/** @jsx jsx */
import { useStaticQuery, graphql } from "gatsby"
import { jsx, Flex } from "theme-ui"
import Img from "gatsby-image"

export default () => {
  // todo: Use fragment for props to replace regex filter term
  const data = useStaticQuery(graphql`query {
      allInstaNode(filter: {caption: {regex: "/.*#crowandapricot.*/g"}}) {
          edges {
              node {
                  id
                  mediaType
                  preview
                  original
                  timestamp
                  caption
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 300, quality: 95) {
                              ...GatsbyImageSharpFluid
                          }
                      }
                  }
              }
          }
      }
  }`)

  return (
    <Flex bg='gray' sx={{flexWrap: "wrap", flexDirection: "row", borderRadius: "10px"}}>
      {data.allInstaNode.edges.map(({ node }) => (
        <Img fluid={node.localFile.childImageSharp.fluid} sx={{ width: "300px", margin: "8px 24px" }}/>
      ))}</Flex>
  )
}

import React from "react";
import Header from "../components/header";
import * as H from "../components/headings"
import Instagram from "../components/instagram-grid"
import * as C from "../components/containers"

export default props => (
  <div>
    <Header />
    <C.centered>
      <H.h1>Crow & Apricot</H.h1>
      <Instagram/>
    </C.centered>
  </div>
);